/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

// in gastby-browser.js
exports.shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const { pathname } = location

  const currentPosition = getSavedScrollPosition(location)
  const queriedPosition = getSavedScrollPosition({ pathname: `/` })

var centerContainer = document.getElementById("center-container");
var topBarHeight = getElemDistance(centerContainer);;

var menu = document.getElementById("main-menu")
var s = menu.querySelector("a.selected");
var selectedScrollAmount = getElemDistance(s);

document.getElementById("main-menu").scrollTo(0,selectedScrollAmount - topBarHeight);

  return false
}


// Get an element's distance from the top of the page
var getElemDistance = function ( elem ) {
    var location = 0;
    if (elem != null && elem.offsetParent) {
        do {
            location += elem.offsetTop;
            elem = elem.offsetParent;
        } while (elem);
    }
    return location >= 0 ? location : 0;
};

